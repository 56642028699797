<template>
  <div class="home">
    <Swiper ref="mySwiper" :options="swiperOptions">
      <SwiperSlide>
        <div class="item">
          <p class="title  defaultTitle" :class="{'showTitle':activeIndex==0}">肆意购买，奢侈随心</p>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div class="pages">
          <img src="../assets/jj.png" class="jjPng" :class="{'rightIn':activeIndex==1}" alt="">
          <div class="info" :class="{'leftIn':activeIndex==1}">
            <p class="title">肆奢简介</p>
            <p class="title_Detail">肆奢（SISHE) 致力于打造奢侈品箱包服务多元化的平台，总部位于浙江省杭州市，主要业务涉及奢侈品箱包鉴定、二手回收、修复、养护、换购、寄卖、网上销售服务等主营业务。
              肆奢（SISHE）拥有目前国内专业奢侈品箱包鉴定团队以及修复养护工厂，既能让用户把手中闲置的奢侈品箱包进行安全可靠的流动交易，也能让用户放心的去购买，探索肆意奢华的体验，是一家值得信赖的奢侈品箱包服务平台。
              肆奢小程序是一个二手中高端奢侈品交易网络平台，主要为用户提供奢侈品查询、评估、鉴定、回收、社交等追求生活品质服务，致力于为中高端消费人群打造一个可靠便捷实惠的闲置奢侈品箱包网络交易圈。
            </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div class="pages pageBgcolor">
          <div class="youTitle">我们的优势</div>
          <div class="images">
            <div>
              <img class="img1" :class="{'imgShow':activeIndex==2}" src="../assets/youshi/p1.png" alt="">
              <img class="img2" src="../assets/youshi/s1.png" alt="">
            </div>
            <div>
              <img class="img1" :class="{'imgShow':activeIndex==2}" src="../assets/youshi/p2.png" alt="">
              <img class="img2" src="../assets/youshi/s2.png" alt="">
            </div>
            <div>
              <img class="img1" :class="{'imgShow':activeIndex==2}" src="../assets/youshi/p3.png" alt="">
              <img class="img2" src="../assets/youshi/s3.png" alt="">
            </div>
          </div>
          <div class="images ">
            <div>
              <img class="img1" :class="{'imgShow':activeIndex==2}" src="../assets/youshi/p4.png" alt="">
              <img class="img2" src="../assets/youshi/s4.png" alt="">
            </div>
            <div>
              <img class="img1" :class="{'imgShow':activeIndex==2}" src="../assets/youshi/p5.png" alt="">
              <img class="img2" src="../assets/youshi/s5.png" alt="">
            </div>
            <div>
              <img class="img1" :class="{'imgShow':activeIndex==2}" src="../assets/youshi/p6.png" alt="">
              <img class="img2" src="../assets/youshi/s6.png" alt="">
            </div>
          </div>
        </div>
      </SwiperSlide>
      <swiper-slide>
        <div class="pages pageCenter">
          <div class="youTitle titleMargin">回收&售卖</div>
          <div class="youTopList">
            <div>
              <img class="default" :class="{'showIn':activeIndex==3}" src="../assets/refound/p1.png" alt="">
              <p>免费估价</p>
            </div>
            <div>
              <img class="default2" :class="{'showIn':activeIndex==3}" src="../assets/refound/p2.png" alt="">
              <p>上门回收</p>
            </div>
            <div>
              <img class="default3" :class="{'showIn':activeIndex==3}" src="../assets/refound/p3.png" alt="">
              <p>顺丰包邮</p>
            </div>
            <div>
              <img class="default4" src="../assets/refound/p4.png" alt="" :class="{'showIn':activeIndex==3}">
              <p>专业鉴定</p>
            </div>
            <div>
              <img class="default5" src="../assets/refound/p5.png" alt="" :class="{'showIn':activeIndex==3}">
              <p>免费估价</p>
            </div>
          </div>
          <div class="youTopList2">
            <div>
              <img class="default6" src="../assets/refound/n1.png" alt="" :class="{'showIn':activeIndex==3}">
              <div>
                <p>正品保证</p>
                <p>专业质检</p>
              </div>
            </div>
            <div>
              <img class="default7" src="../assets/refound/n2.png" alt="" :class="{'showIn':activeIndex==3}">
              <div>
                <p>免费中检</p>
                <p>发货带标</p>
              </div>
            </div>
            <div>
              <img class="default8" src="../assets/refound/n3.png" alt="" :class="{'showIn':activeIndex==3}">
              <div>
                <p>4道质检</p>
                <p>全程监督</p>
              </div>
            </div>
            <div>
              <img class="default9" src="../assets/refound/n4.png" alt="" :class="{'showIn':activeIndex==3}">
              <div>
                <p>售前售后</p>
                <p>服务优质</p>
              </div>
            </div>

          </div>
        </div>
      </swiper-slide>
      <SwiperSlide>
        <div class="pages2">
          <div class="pages pageBgcolor">
            <div class="youTitle">关于售卖</div>
            <div class="shouMaiText" :class="{'topIn':activeIndex==4}">
              <p>肆意选购，奢侈随心，肆奢二手馆专注箱包鉴定，支持中检，为每一位女士推荐专属的优质包包</p>
              <p class="title">【售卖讲解】</p>
              <p> 我们有专业的售卖讲解人员，在专业角度根据市场流行趋势，无论是在职场，生活，旅行，社交等，都能为您提供搭配灵感，购买到属于你的专属产品。</p>
              <p class="title">【专业服务】</p>
              <p> 本着客户为上帝原则，我们会耐心讲解，您在挑选商品无措时，随时可以咨询我们，肆奢会在第一时间解决您的疑惑。
              </p>
            </div>
            <div class="shouMaiImage" :class="{'downIn':activeIndex==4}">
              <img src="../assets/sm.png" alt="">
            </div>
          </div>
          <div class="foot">
            <p class="PhoneNum">客服热线：133 9768 2143</p>
            <p class="PhoneNum PhoneNum2"><a   href="https://beian.miit.gov.cn/">浙ICP备2021005922号-1</a></p>
            <div class="qrcode">
              <div>
                <img src="../assets/qrcode.png" alt="">
                <p class="label">支付宝扫码-肆奢-奢侈品回收</p>
              </div>
              <div>
                <img src="../assets/qrcode2.png" alt="">
                <p class="label">微信扫码-肆奢-二手馆</p>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <div class="swiper-pagination" slot="pagination">
        <div v-for="(val,index) in 5" :key="index" @click="setIndex(index)" :class="{'active':index==activeIndex}"></div>
      </div>
    </Swiper>
  </div>
</template>

<script>
import {Swiper, SwiperSlide, directive} from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'

export default {
  name: "Home",
  data() {
    return {
      activeIndex: -1,
      scroll: true,
      swiperOptions: {
        width: window.innerWidth,
        height: window.innerHeight,
        roundLengths: true,
        slidesPerGroupSkip: true,
        speed: 600,
        direction: 'vertical',
        on: {
          resize: function () {
            this.params.width = window.innerWidth;
            this.params.height = window.innerHeight;
            this.update();
          },
          transitionStart: this.transitionStart,
          transitionEnd: this.transitionEnd,
        },
        pagination: {
          el: '.swiper-pagination',
        },

      }
    }
  },
  methods: {
    transitionStart() {
      this.scroll = false;
    },
    transitionEnd(e) {
      this.activeIndex = e.activeIndex;
      setTimeout(() => {
        this.scroll = true;
      }, 200)
    },
    setIndex(index) {
      this.swiper.slideTo(index, 600, false)
    },
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  directives: {
    swiper: directive
  },
  mounted() {
    window.onmousewheel = (e) => {
      if (this.scroll) {
        if (e.isTrusted && e.deltaY > 50) {
          this.swiper.slideNext(600, false)
        }
        if (e.isTrusted && e.deltaY <= -50) {
          this.swiper.slidePrev(600, false)
        }
      }
    }
    setTimeout(() => {
      this.activeIndex = 0
    }, 300)
  },
}
</script>
<style lang="scss">
.swiper-pagination {
  position: fixed;
  top: 0;
  right: 0;
  width: 1rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > div {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #f5f5f5;
    margin: 6px;
    cursor: pointer;
  }

  .active {
    background: #c1c1c1;
  }
}
</style>
<style scoped lang="scss">

* {
  transition: all 0.5s;
}

.foot {
  width: 100%;
  height: 4.34rem;
  background: #222;
  position: relative;

  .PhoneNum {
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    left: 2.4rem;
    top: 0;
    margin: auto;
    font-size: 0.2rem;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    color: #FFFFFF;
  }

  .PhoneNum2 {
    margin-top: 40px;

    a {
      color: #FFFFFF;
    }
  }

  .qrcode {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 2.4rem;

    > div {
      margin-left: 1.0rem;
    }

    .label {
      font-size: 0.2rem;
      font-family: Alibaba PuHuiTi;
      font-weight: 400;
      color: #FFFFFF;
      margin-top: 0.17rem;
    }

    img {
      width: 1.48rem;
      height: 1.48rem;
      display: block;
      margin: auto;
    }
  }
}

.pageBgcolor {
  background: #F6F1EB;
  position: relative;
  height: 100vh;
  width: 100%;

  .images {
    display: grid;
    box-sizing: border-box;
    //padding: 0 2.4rem;
    width: 14.4rem;
    grid-template-columns:auto auto auto;
    grid-template-rows: auto;
    grid-gap: 0.3rem;
    margin: auto;
    margin-top: 0.7rem;
    justify-items: center;

    > div {
      position: relative;
      width: 4.2rem;
      height: 3.6rem;

      &:hover {
        .img2 {
          cursor: pointer;
          opacity: 1;
        }
      }

      .img1 {
        position: absolute;
        width: 4.2rem;
        left: 0;
        transition: all 0.6s;
        opacity: 0;
        transform: rotateY(90deg);
      }

      .img2 {
        width: 4.2rem;
        position: absolute;
        transition: all .8s;
        left: 0;
        opacity: 0;
      }
    }

    .imgShow {
      transform: rotateY(0deg) !important;
      opacity: 1 !important;
    }

  }
}

.home {
  width: 100%;
  mim-width: 14rem;
  height: 100vh;
  overflow: hidden;
}

.item {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/home.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;

  .title {
    font-size: 0.68rem;
    font-family: Alibaba PuHuiTi;
    font-weight: bold;
    color: #FFFFFF;
    text-shadow: 0rem 0rem 0rem #000000;
    letter-spacing: 0.04rem;
  }

  .defaultTitle {
    opacity: 0;
    transition: all 1s;
    transform: translateY(1rem) rotateY(90deg);
  }

  .showTitle {
    opacity: 1;
    transform: translateY(0) rotateY(0);
  }
}

.pageCenter {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pages2 {
  height: 100vh;
  overflow: auto;
}

.pages {
  width: 100%;
  height: 100vh;
  position: relative;

  .shouMaiImage {
    width: 6rem;
    position: absolute;
    right: 3.27rem;
    top: 2.22rem;
    opacity: 0;
    transition: all 2s;
    transform: translateY(1rem);

    img {
      width: 6rem;
      height: 6.8rem;
      position: absolute;
      left: 0;
      z-index: 2;
    }

    &:after {
      content: "";
      display: inline-block;
      width: 6rem;
      height: 6.8rem;
      position: absolute;
      background: white;
      left: 0.3rem;
      top: 0.3rem;
      z-index: 1;
    }
  }

  .downIn {
    transform: translateY(0);
    opacity: 1;
  }

  .shouMaiText {
    width: 5.9rem;
    position: absolute;
    top: 3.2rem;
    left: 2.6rem;
    opacity: 0;
    transition: 1s all;
    transform: translateY(-1rem);

    .title {
      margin-top: 0.6rem;
      margin-bottom: 0.04rem;
    }

    > p {
      font-size: 0.24rem;
      font-family: Alibaba PuHuiTi;
      font-weight: 400;
      color: #000000;
    }
  }

  .topIn {
    transform: translateY(0);
    opacity: 1;
  }

  .youTopList2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 3.5rem;
    margin-top: 2.4rem;

    img {
      width: 0.82rem;
      height: 0.56rem;
      margin-right: 0.23rem;
    }

    > div {
      display: flex;
      align-items: center;
    }

    p {
      font-size: 0.22rem;
      font-family: Alibaba PuHuiTi;
      font-weight: 400;
      color: #000000;
      text-align: center;
    }

    .default6 {
      opacity: 0;
      transition: all 1.7s;
      transform: perspective(0) rotate3d(1, 0, 0, 90deg);
    }

    .default7 {
      opacity: 0;
      transition: all 1.8s;
      transform: perspective(0) rotate3d(1, 0, 0, 90deg);
    }

    .default8 {
      opacity: 0;
      transition: all 1.9s;
      transform: perspective(0) rotate3d(1, 0, 0, 90deg);
    }

    .default9 {
      opacity: 0;
      transition: all 2s;
      transform: perspective(0) rotate3d(1, 0, 0, 90deg);
    }


    .showIn {
      transform: perspective(0px) !important;
      opacity: 1;
    }
  }


  .youTopList {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 2.4rem;
    margin-top: 1rem;


    > div {
      position: relative;
    }

    p {
      font-size: 0.22rem;
      font-family: Alibaba PuHuiTi;
      font-weight: 400;
      color: #000000;
      white-space: nowrap;
      margin-top: 0.19rem;
    }

    img {
      width: 0.8rem;
      height: 0.8rem;
      display: block;
      margin: auto;
      //transition: 0.6s linear;
    }

    .default {
      opacity: 0;
      transition: all 0.6s;
      transform: perspective(0) rotate3d(1, 0, 0, 90deg);
    }

    .default2 {
      opacity: 0;
      transition: all 0.8s;
      transform: perspective(0) rotate3d(1, 0, 0, 90deg);
    }

    .default3 {
      opacity: 0;
      transition: all 1s;
      transform: perspective(0) rotate3d(1, 0, 0, 90deg);
    }

    .default4 {
      opacity: 0;
      transition: all 1.2s;
      transform: perspective(0) rotate3d(1, 0, 0, 90deg);
    }

    .default5 {
      opacity: 0;
      transition: all 1.4s;
      transform: perspective(0) rotate3d(1, 0, 0, 90deg);
    }

    .showIn {
      transform: perspective(0px) !important;
      opacity: 1;
    }

  }


  .youTitle {
    font-size: 0.42rem;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    color: #000000;
    text-align: center;
    box-sizing: border-box;
    padding-top: 0.5rem;
    position: relative;

    &:after {
      content: "";
      display: inline-block;
      width: 0.36rem;
      height: 0.04rem;
      background: #000;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -0.2rem;
      margin: auto;
    }
  }

  .title {
    font-size: 0.54rem;
    font-family: Alibaba PuHuiTi;
    font-weight: bold;
    color: #602121;
  }

  .title_Detail {
    width: 6.44rem;
    font-size: 0.2rem;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    color: #000000;
    margin-top: 0.5rem;
  }

  .jjPng {
    width: 6.3rem;
    height: 7.1rem;
    position: absolute;
    left: 2.8rem;
    top: 1.28rem;
    transition: all 0.8s ease;
    transform: translateX(-1rem);
    opacity: 0;
  }

  .info {
    position: absolute;
    right: 2.4rem;
    top: 2.3rem;
    transition: all 0.8s ease;
    opacity: 0;
    transform: translateX(1rem);
  }

  .rightIn {
    transform: translateX(0);
    opacity: 1;
  }

  .leftIn {
    transform: translateX(0);
    opacity: 1;
  }
}


</style>
