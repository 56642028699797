import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import Fade from "@/components/animate/Fade";
import fadeP from "@/components/animate/fadeP";
import fadeSpan from "@/components/animate/fadeSpan";

// import './utils/flexble_css'
// import './utils/flexble'
Vue.config.productionTip = false;


//designWidth:设计稿的实际宽度值，需要根据实际设置
//maxWidth:制作稿的最大宽度值，需要根据实际设置
//这段js的最后面有两个参数记得要设置，一个为设计稿实际宽度，一个为制作稿最大宽度，例如设计稿为750，最大宽度为750，则为(750,750)
(function (designWidth, maxWidth) {
    var doc = document,
        win = window,
        docEl = doc.documentElement,
        remStyle = document.createElement("style"),
        tid;

    function refreshRem() {
        var width = docEl.getBoundingClientRect().width;
        maxWidth = maxWidth || 1920;
        width > maxWidth && (width = maxWidth);
        var rem = width * 98 / designWidth;
        if (rem <= 65) rem = 70;
        remStyle.innerHTML = 'html{font-size:' + rem + 'px;}';
    }

    if (docEl.firstElementChild) {
        docEl.firstElementChild.appendChild(remStyle);
    } else {
        var wrap = doc.createElement("div");
        wrap.appendChild(remStyle);
        doc.write(wrap.innerHTML);
        wrap = null;
    }
    //要等 wiewport 设置好后才能执行 refreshRem，不然 refreshRem 会执行2次；
    refreshRem();

    win.addEventListener("resize", function () {
        clearTimeout(tid); //防止执行两次
        tid = setTimeout(refreshRem, 300);
    }, false);
})(1920, 1920)


Vue.component('animateFade', Fade)
Vue.component('animateFadep', fadeP)
Vue.component('animateFadeSpan', fadeSpan)
new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app')
