<template>
  <p class="notop " :class="{gotop:Show}">
    <slot></slot>
  </p>
</template>

<script>

import fadeMinx from "@/components/animate/fadeMinx";

export default {
  name: "Fadep",
  mixins: [fadeMinx]
}
</script>
