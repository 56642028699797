<template>
  <span class="notop default" :class="{'gotop':Show}">
    <slot></slot>
  </span>
</template>

<script>
import fadeMinx from "@/components/animate/fadeMinx";

export default {
  name: "Fadep",
  props: {
    Show: {
      type: Boolean,
      default: false,
    }
  },
}
</script>

