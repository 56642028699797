import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'


const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)


const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: "*",
        name: "Home",
        component: Home,
    }
]

const router = new VueRouter({
    mode: 'history',
    routes,
})

router.afterEach(((to, from) => {
    window.scrollTo({top: 0})
}))

export default router
