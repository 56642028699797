<template>
  <div class="notop " :class="{gotop:Show}">
    <slot></slot>
  </div>
</template>

<script>
import fadeMinx from "@/components/animate/fadeMinx";

export default {
  name: "Fade",
  mixins: [fadeMinx]
}
</script>
