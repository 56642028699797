<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style>
* {
  margin: 0;
  padding: 0;
}

#app {
  margin: auto;
}

</style>
<script>
import {mapMutations, mapState} from 'vuex'
export default {
  data() {
    return {
      showProHeader: false,
      bgShow: false,
    }
  },
  computed: {
    ...mapState(['scrollTop']),
  },
  watch: {
    $route: {
      handler: function (val) {
        let Applist = ['Home', 'propaganda']
        let routeList = ['shadowApp', 'teaching', 'jianronginfo', 'problem', 'parm', 'pro']
        this.showProHeader = routeList.includes(val.name);
        this.bgShow = Applist.includes(val.name);
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations(['SETSCROLLTOP'])
  },
  mounted() {
    document.addEventListener('scroll', () => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      this.SETSCROLLTOP(scrollTop)
    })
  }
}
</script>

<style lang="scss">

@font-face{
  font-family: 'Alibaba PuHuiTi';
  src : url('./assets/font/PuHuiTi.ttf');
}

.notop {
  visibility: hidden;
  transform: translateY(50px);
  opacity: 0;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}


.fadeAnimate {
  @extend .notop;
}

.gotop {
  transform: translateY(0) !important;
  opacity: 1 !important;
  visibility: visible;
}


@keyframes sop {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(20px);
  }

}

.an {
  animation: sop 0.8s infinite alternate;
}


</style>
