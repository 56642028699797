import {mapState} from "vuex";

export default {
    data() {
        return {
            offsetTop: 0,
            Show: false,
        }
    },
    computed: {
        ...mapState(['scrollTop']),
    },
    watch: {
        scrollTop: {
            handler: function (val) {
                if (val >= 0) {
                    setTimeout(() => {
                        this.Show = val + window.innerHeight >= this.offsetTop
                    }, 50)
                    return
                }
                this.Show = false
            },
            immediate: true
        }
    },
    mounted() {
        this.offsetTop = this.$el.getBoundingClientRect().top + window.pageYOffset
        window.onresize = () => {
            this.offsetTop = this.$el.getBoundingClientRect().top + window.pageYOffset
        }
    }
}
